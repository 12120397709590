var endpointMap = {
	ar: {
		"com.a2z.it.kb.ArticlesByCategory": "translation-ar.json",
		"com.a2z.it.kb.EditButton": "translation-ar.json",
		"com.a2z.it.kb.EditedDate": "translation-ar.json",
		"com.a2z.it.kb.Footer": "translation-ar.json",
		"com.a2z.it.kb.Header": "translation-ar.json",
		"com.a2z.it.kb.InternalServerErrorPage": "translation-ar.json",
		"com.a2z.it.kb.LandingPageNewArticles": "translation-ar.json",
		"com.a2z.it.kb.LandingPageRecentlyUpdated": "translation-ar.json",
		"com.a2z.it.kb.LanguagePicker": "translation-ar.json",
		"com.a2z.it.kb.NotFoundPage": "translation-ar.json",
		"com.a2z.it.kb.RelatedArticles": "translation-ar.json",
		"com.a2z.it.kb.RequestUpdateButton": "translation-ar.json",
		"com.a2z.it.kb.SearchBar": "translation-ar.json",
		"com.a2z.it.kb.SearchResultsPage": "translation-ar.json",
		"com.a2z.it.kb.Sitemap": "translation-ar.json"
	},
	"ar-AE": {
		"com.a2z.it.kb.ArticlesByCategory": "translation-ar.json",
		"com.a2z.it.kb.EditButton": "translation-ar.json",
		"com.a2z.it.kb.EditedDate": "translation-ar.json",
		"com.a2z.it.kb.Footer": "translation-ar.json",
		"com.a2z.it.kb.Header": "translation-ar.json",
		"com.a2z.it.kb.InternalServerErrorPage": "translation-ar.json",
		"com.a2z.it.kb.LandingPageNewArticles": "translation-ar.json",
		"com.a2z.it.kb.LandingPageRecentlyUpdated": "translation-ar.json",
		"com.a2z.it.kb.LanguagePicker": "translation-ar.json",
		"com.a2z.it.kb.NotFoundPage": "translation-ar.json",
		"com.a2z.it.kb.RelatedArticles": "translation-ar.json",
		"com.a2z.it.kb.RequestUpdateButton": "translation-ar.json",
		"com.a2z.it.kb.SearchBar": "translation-ar.json",
		"com.a2z.it.kb.SearchResultsPage": "translation-ar.json",
		"com.a2z.it.kb.Sitemap": "translation-ar.json"
	},
	de: {
		"com.a2z.it.kb.ArticlesByCategory": "translation-de.json",
		"com.a2z.it.kb.EditButton": "translation-de.json",
		"com.a2z.it.kb.EditedDate": "translation-de.json",
		"com.a2z.it.kb.Footer": "translation-de.json",
		"com.a2z.it.kb.Header": "translation-de.json",
		"com.a2z.it.kb.InternalServerErrorPage": "translation-de.json",
		"com.a2z.it.kb.LandingPageNewArticles": "translation-de.json",
		"com.a2z.it.kb.LandingPageRecentlyUpdated": "translation-de.json",
		"com.a2z.it.kb.LanguagePicker": "translation-de.json",
		"com.a2z.it.kb.NotFoundPage": "translation-de.json",
		"com.a2z.it.kb.RelatedArticles": "translation-de.json",
		"com.a2z.it.kb.RequestUpdateButton": "translation-de.json",
		"com.a2z.it.kb.SearchBar": "translation-de.json",
		"com.a2z.it.kb.SearchResultsPage": "translation-de.json",
		"com.a2z.it.kb.Sitemap": "translation-de.json"
	},
	"de-DE": {
		"com.a2z.it.kb.ArticlesByCategory": "translation-de.json",
		"com.a2z.it.kb.EditButton": "translation-de.json",
		"com.a2z.it.kb.EditedDate": "translation-de.json",
		"com.a2z.it.kb.Footer": "translation-de.json",
		"com.a2z.it.kb.Header": "translation-de.json",
		"com.a2z.it.kb.InternalServerErrorPage": "translation-de.json",
		"com.a2z.it.kb.LandingPageNewArticles": "translation-de.json",
		"com.a2z.it.kb.LandingPageRecentlyUpdated": "translation-de.json",
		"com.a2z.it.kb.LanguagePicker": "translation-de.json",
		"com.a2z.it.kb.NotFoundPage": "translation-de.json",
		"com.a2z.it.kb.RelatedArticles": "translation-de.json",
		"com.a2z.it.kb.RequestUpdateButton": "translation-de.json",
		"com.a2z.it.kb.SearchBar": "translation-de.json",
		"com.a2z.it.kb.SearchResultsPage": "translation-de.json",
		"com.a2z.it.kb.Sitemap": "translation-de.json"
	},
	en: {
		"com.a2z.it.kb.ArticlesByCategory": "translation-en.json",
		"com.a2z.it.kb.EditButton": "translation-en.json",
		"com.a2z.it.kb.EditedDate": "translation-en.json",
		"com.a2z.it.kb.Footer": "translation-en.json",
		"com.a2z.it.kb.Header": "translation-en.json",
		"com.a2z.it.kb.InternalServerErrorPage": "translation-en.json",
		"com.a2z.it.kb.LandingPageNewArticles": "translation-en.json",
		"com.a2z.it.kb.LandingPageRecentlyUpdated": "translation-en.json",
		"com.a2z.it.kb.LanguagePicker": "translation-en.json",
		"com.a2z.it.kb.NotFoundPage": "translation-en.json",
		"com.a2z.it.kb.RelatedArticles": "translation-en.json",
		"com.a2z.it.kb.RequestUpdateButton": "translation-en.json",
		"com.a2z.it.kb.SearchBar": "translation-en.json",
		"com.a2z.it.kb.SearchResultsPage": "translation-en.json",
		"com.a2z.it.kb.Sitemap": "translation-en.json"
	},
	"en-US": {
		"com.a2z.it.kb.ArticlesByCategory": "translation-en.json",
		"com.a2z.it.kb.EditButton": "translation-en.json",
		"com.a2z.it.kb.EditedDate": "translation-en.json",
		"com.a2z.it.kb.Footer": "translation-en.json",
		"com.a2z.it.kb.Header": "translation-en.json",
		"com.a2z.it.kb.InternalServerErrorPage": "translation-en.json",
		"com.a2z.it.kb.LandingPageNewArticles": "translation-en.json",
		"com.a2z.it.kb.LandingPageRecentlyUpdated": "translation-en.json",
		"com.a2z.it.kb.LanguagePicker": "translation-en.json",
		"com.a2z.it.kb.NotFoundPage": "translation-en.json",
		"com.a2z.it.kb.RelatedArticles": "translation-en.json",
		"com.a2z.it.kb.RequestUpdateButton": "translation-en.json",
		"com.a2z.it.kb.SearchBar": "translation-en.json",
		"com.a2z.it.kb.SearchResultsPage": "translation-en.json",
		"com.a2z.it.kb.Sitemap": "translation-en.json"
	},
	es: {
		"com.a2z.it.kb.ArticlesByCategory": "translation-es.json",
		"com.a2z.it.kb.EditButton": "translation-es.json",
		"com.a2z.it.kb.EditedDate": "translation-es.json",
		"com.a2z.it.kb.Footer": "translation-es.json",
		"com.a2z.it.kb.Header": "translation-es.json",
		"com.a2z.it.kb.InternalServerErrorPage": "translation-es.json",
		"com.a2z.it.kb.LandingPageNewArticles": "translation-es.json",
		"com.a2z.it.kb.LandingPageRecentlyUpdated": "translation-es.json",
		"com.a2z.it.kb.LanguagePicker": "translation-es.json",
		"com.a2z.it.kb.NotFoundPage": "translation-es.json",
		"com.a2z.it.kb.RelatedArticles": "translation-es.json",
		"com.a2z.it.kb.RequestUpdateButton": "translation-es.json",
		"com.a2z.it.kb.SearchBar": "translation-es.json",
		"com.a2z.it.kb.SearchResultsPage": "translation-es.json",
		"com.a2z.it.kb.Sitemap": "translation-es.json"
	},
	"es-ES": {
		"com.a2z.it.kb.ArticlesByCategory": "translation-es.json",
		"com.a2z.it.kb.EditButton": "translation-es.json",
		"com.a2z.it.kb.EditedDate": "translation-es.json",
		"com.a2z.it.kb.Footer": "translation-es.json",
		"com.a2z.it.kb.Header": "translation-es.json",
		"com.a2z.it.kb.InternalServerErrorPage": "translation-es.json",
		"com.a2z.it.kb.LandingPageNewArticles": "translation-es.json",
		"com.a2z.it.kb.LandingPageRecentlyUpdated": "translation-es.json",
		"com.a2z.it.kb.LanguagePicker": "translation-es.json",
		"com.a2z.it.kb.NotFoundPage": "translation-es.json",
		"com.a2z.it.kb.RelatedArticles": "translation-es.json",
		"com.a2z.it.kb.RequestUpdateButton": "translation-es.json",
		"com.a2z.it.kb.SearchBar": "translation-es.json",
		"com.a2z.it.kb.SearchResultsPage": "translation-es.json",
		"com.a2z.it.kb.Sitemap": "translation-es.json"
	},
	fr: {
		"com.a2z.it.kb.ArticlesByCategory": "translation-fr.json",
		"com.a2z.it.kb.EditButton": "translation-fr.json",
		"com.a2z.it.kb.EditedDate": "translation-fr.json",
		"com.a2z.it.kb.Footer": "translation-fr.json",
		"com.a2z.it.kb.Header": "translation-fr.json",
		"com.a2z.it.kb.InternalServerErrorPage": "translation-fr.json",
		"com.a2z.it.kb.LandingPageNewArticles": "translation-fr.json",
		"com.a2z.it.kb.LandingPageRecentlyUpdated": "translation-fr.json",
		"com.a2z.it.kb.LanguagePicker": "translation-fr.json",
		"com.a2z.it.kb.NotFoundPage": "translation-fr.json",
		"com.a2z.it.kb.RelatedArticles": "translation-fr.json",
		"com.a2z.it.kb.RequestUpdateButton": "translation-fr.json",
		"com.a2z.it.kb.SearchBar": "translation-fr.json",
		"com.a2z.it.kb.SearchResultsPage": "translation-fr.json",
		"com.a2z.it.kb.Sitemap": "translation-fr.json"
	},
	"fr-FR": {
		"com.a2z.it.kb.ArticlesByCategory": "translation-fr.json",
		"com.a2z.it.kb.EditButton": "translation-fr.json",
		"com.a2z.it.kb.EditedDate": "translation-fr.json",
		"com.a2z.it.kb.Footer": "translation-fr.json",
		"com.a2z.it.kb.Header": "translation-fr.json",
		"com.a2z.it.kb.InternalServerErrorPage": "translation-fr.json",
		"com.a2z.it.kb.LandingPageNewArticles": "translation-fr.json",
		"com.a2z.it.kb.LandingPageRecentlyUpdated": "translation-fr.json",
		"com.a2z.it.kb.LanguagePicker": "translation-fr.json",
		"com.a2z.it.kb.NotFoundPage": "translation-fr.json",
		"com.a2z.it.kb.RelatedArticles": "translation-fr.json",
		"com.a2z.it.kb.RequestUpdateButton": "translation-fr.json",
		"com.a2z.it.kb.SearchBar": "translation-fr.json",
		"com.a2z.it.kb.SearchResultsPage": "translation-fr.json",
		"com.a2z.it.kb.Sitemap": "translation-fr.json"
	},
	he: {
		"com.a2z.it.kb.ArticlesByCategory": "translation-he.json",
		"com.a2z.it.kb.EditButton": "translation-he.json",
		"com.a2z.it.kb.EditedDate": "translation-he.json",
		"com.a2z.it.kb.Footer": "translation-he.json",
		"com.a2z.it.kb.Header": "translation-he.json",
		"com.a2z.it.kb.InternalServerErrorPage": "translation-he.json",
		"com.a2z.it.kb.LandingPageNewArticles": "translation-he.json",
		"com.a2z.it.kb.LandingPageRecentlyUpdated": "translation-he.json",
		"com.a2z.it.kb.LanguagePicker": "translation-he.json",
		"com.a2z.it.kb.NotFoundPage": "translation-he.json",
		"com.a2z.it.kb.RelatedArticles": "translation-he.json",
		"com.a2z.it.kb.RequestUpdateButton": "translation-he.json",
		"com.a2z.it.kb.SearchBar": "translation-he.json",
		"com.a2z.it.kb.SearchResultsPage": "translation-he.json",
		"com.a2z.it.kb.Sitemap": "translation-he.json"
	},
	"he-IL": {
		"com.a2z.it.kb.ArticlesByCategory": "translation-he.json",
		"com.a2z.it.kb.EditButton": "translation-he.json",
		"com.a2z.it.kb.EditedDate": "translation-he.json",
		"com.a2z.it.kb.Footer": "translation-he.json",
		"com.a2z.it.kb.Header": "translation-he.json",
		"com.a2z.it.kb.InternalServerErrorPage": "translation-he.json",
		"com.a2z.it.kb.LandingPageNewArticles": "translation-he.json",
		"com.a2z.it.kb.LandingPageRecentlyUpdated": "translation-he.json",
		"com.a2z.it.kb.LanguagePicker": "translation-he.json",
		"com.a2z.it.kb.NotFoundPage": "translation-he.json",
		"com.a2z.it.kb.RelatedArticles": "translation-he.json",
		"com.a2z.it.kb.RequestUpdateButton": "translation-he.json",
		"com.a2z.it.kb.SearchBar": "translation-he.json",
		"com.a2z.it.kb.SearchResultsPage": "translation-he.json",
		"com.a2z.it.kb.Sitemap": "translation-he.json"
	},
	und: {
		"com.a2z.it.kb.ArticlesByCategory": "translation.json",
		"com.a2z.it.kb.EditButton": "translation.json",
		"com.a2z.it.kb.EditedDate": "translation.json",
		"com.a2z.it.kb.Footer": "translation.json",
		"com.a2z.it.kb.Header": "translation.json",
		"com.a2z.it.kb.InternalServerErrorPage": "translation.json",
		"com.a2z.it.kb.LandingPageNewArticles": "translation.json",
		"com.a2z.it.kb.LandingPageRecentlyUpdated": "translation.json",
		"com.a2z.it.kb.LanguagePicker": "translation.json",
		"com.a2z.it.kb.NotFoundPage": "translation.json",
		"com.a2z.it.kb.RelatedArticles": "translation.json",
		"com.a2z.it.kb.RequestUpdateButton": "translation.json",
		"com.a2z.it.kb.SearchBar": "translation.json",
		"com.a2z.it.kb.SearchResultsPage": "translation.json",
		"com.a2z.it.kb.Sitemap": "translation.json"
	},
	zh: {
		"com.a2z.it.kb.ArticlesByCategory": "translation-zh.json",
		"com.a2z.it.kb.EditButton": "translation-zh.json",
		"com.a2z.it.kb.EditedDate": "translation-zh.json",
		"com.a2z.it.kb.Footer": "translation-zh.json",
		"com.a2z.it.kb.Header": "translation-zh.json",
		"com.a2z.it.kb.InternalServerErrorPage": "translation-zh.json",
		"com.a2z.it.kb.LandingPageNewArticles": "translation-zh.json",
		"com.a2z.it.kb.LandingPageRecentlyUpdated": "translation-zh.json",
		"com.a2z.it.kb.LanguagePicker": "translation-zh.json",
		"com.a2z.it.kb.NotFoundPage": "translation-zh.json",
		"com.a2z.it.kb.RelatedArticles": "translation-zh.json",
		"com.a2z.it.kb.RequestUpdateButton": "translation-zh.json",
		"com.a2z.it.kb.SearchBar": "translation-zh.json",
		"com.a2z.it.kb.SearchResultsPage": "translation-zh.json",
		"com.a2z.it.kb.Sitemap": "translation-zh.json"
	},
	"zh-CN": {
		"com.a2z.it.kb.ArticlesByCategory": "translation-zh.json",
		"com.a2z.it.kb.EditButton": "translation-zh.json",
		"com.a2z.it.kb.EditedDate": "translation-zh.json",
		"com.a2z.it.kb.Footer": "translation-zh.json",
		"com.a2z.it.kb.Header": "translation-zh.json",
		"com.a2z.it.kb.InternalServerErrorPage": "translation-zh.json",
		"com.a2z.it.kb.LandingPageNewArticles": "translation-zh.json",
		"com.a2z.it.kb.LandingPageRecentlyUpdated": "translation-zh.json",
		"com.a2z.it.kb.LanguagePicker": "translation-zh.json",
		"com.a2z.it.kb.NotFoundPage": "translation-zh.json",
		"com.a2z.it.kb.RelatedArticles": "translation-zh.json",
		"com.a2z.it.kb.RequestUpdateButton": "translation-zh.json",
		"com.a2z.it.kb.SearchBar": "translation-zh.json",
		"com.a2z.it.kb.SearchResultsPage": "translation-zh.json",
		"com.a2z.it.kb.Sitemap": "translation-zh.json"
	}
};
var arbManifest = {
	endpointMap: endpointMap
};

export { arbManifest as default, endpointMap };
