import "./style.scss";

import { SelectProps } from "@amzn/awsui-components-react/polaris/select";
import { withBundle, WithBundleProps } from "@amzn/react-arb-tools";
import { Select } from "common/components";
import { DEFAULT_LOCALE, LOCALE_KEY } from "config/constants";
import React, { useState } from "react";
import { useLocation } from "react-router-dom";

interface LanguagePickerProps extends WithBundleProps {}

const featureFlag = "localize";

const LanguagePicker = (props: LanguagePickerProps) => {
  const { bundle } = props;
  const { search } = useLocation();

  const browserLanguage = window.navigator.language.split("-")[0];
  const localeLanguage = window.localStorage.getItem("kb-locale") || DEFAULT_LOCALE;

  // English is the default option
  const english: SelectProps.Option = {
    label: "en",
    value: "en",
    description: "English",
  };

  const availableLanguages: SelectProps.Option[] = [
    english,
    { label: "ja", value: "ja", description: "日本語" },
    { label: "zh", value: "zh", description: "中文" },
  ];

  const currentLanguage = ({ value }: SelectProps.Option) =>
    localeLanguage ? value === localeLanguage : value === browserLanguage;

  const [selectedOption, setSelectedOption] = useState<SelectProps.Option>(
    availableLanguages.find(currentLanguage) ?? english
  );

  const handleChange: SelectProps["onChange"] = ({ detail }) => {
    const { value } = detail.selectedOption;

    if (value) {
      setSelectedOption(detail.selectedOption);
      window.localStorage.setItem(LOCALE_KEY, value);
      window.location.reload();
    }
  };

  if (!search.includes(featureFlag)) {
    return null;
  }

  return (
    <div id="kb-portal-language-picker">
      <Select
        ariaLabel={bundle.getMessage("select-aria-label")}
        selectedOption={selectedOption}
        onChange={handleChange}
        options={availableLanguages}
      />
    </div>
  );
};

export default withBundle("com.a2z.it.kb.LanguagePicker")(LanguagePicker);
