import "./style.scss";

import { withBundle, WithBundleProps } from "@amzn/react-arb-tools";
import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";

import Logo from "../../../../public/img/kb_logo.svg";
import { getUsername } from "../../../helpers/profile-helper";
import RequestUpdateButton from "../buttons/RequestUpdateButton";
import LanguagePicker from "../LanguagePicker";
import SearchBar from "../SearchBar";
import UserPhoto from "../UserPhoto";

interface HeaderProps extends WithBundleProps {}

const Header = (props: HeaderProps) => {
  const { bundle } = props;
  const [hideSearchBar, setHideSearchBar] = useState<boolean>(false);
  const [isLandingPage, setIsLandingPage] = useState<boolean>(window.location.pathname === "/");

  const registerEventListeners = () => {
    window.addEventListener("locationchange", handleLocationChange);
    window.addEventListener("scroll", handleScroll);
  };
  const unregisterEventListeners = () => {
    window.removeEventListener("locationchange", handleLocationChange);
    window.removeEventListener("scroll", handleScroll);
  };

  const handleScroll = () => {
    setHideSearchBar(window.scrollY < 175);
  };

  const handleLocationChange = () => {
    setIsLandingPage(window.location.pathname === "/");
  };

  useEffect(() => {
    registerEventListeners();

    return () => {
      unregisterEventListeners();
    };
  }, []);

  if (isLandingPage) {
    return (
      <div className="kb-header">
        <RegularHeader sticky={true} hideSearchBar={hideSearchBar} />
        <ExpandedHeader bundle={bundle} hideSearchBar={hideSearchBar} />
      </div>
    );
  } else {
    return (
      <div className="kb-header sticky">
        <RegularHeader sticky={false} hideSearchBar={false} />
      </div>
    );
  }
};

interface IRegularHeaderProps {
  sticky: boolean;
  hideSearchBar: boolean;
}

/**
 * The sticky header at the top of every page
 */
const RegularHeader = (props: IRegularHeaderProps) => {
  const headerClass = "regular-header " + (props.sticky ? "sticky" : "");
  return (
    <div className={headerClass} key={Date.now()}>
      <div className="header-content">
        <div>
          <Link to="">
            {" "}
            <img id="kbLogo" alt="Knowledge Base Logo" src={Logo as string} />{" "}
          </Link>
        </div>
        {!props.hideSearchBar && <SearchBar />}
        <div id="header-buttons">
          <LanguagePicker />
          <RequestUpdateButton />
          <UserPhoto />
        </div>
        {/* TODO: Create notifications button */}
      </div>
    </div>
  );
};

interface IExpandedHeaderProps extends WithBundleProps {
  hideSearchBar: boolean;
}

/**
 * The Expanded header with user name and search
 * only used on landing page
 */
const ExpandedHeader = (props: IExpandedHeaderProps) => {
  const { bundle } = props;
  const username = getUsername();

  return (
    <div className="expanded-header" key={Date.now()}>
      <div className="expanded-header-content">
        {/* Welcome text */}
        <div className="welcome-text">{bundle.formatMessage("header-title", { username })}</div>
        <div className="welcome-sub-text">{bundle.getMessage("header-text")}</div>
        {props.hideSearchBar && <SearchBar />}
        <div className="common-troubleshooting-topics">
          {bundle.getMessage("common-troubleshooting-topics")}
          <Link to="/categories/AEA">AEA</Link>,<Link to="/categories/Amazon-Account">Amazon Account</Link>,
          <Link to="/categories/Security Key">Security Key</Link>
        </div>
      </div>
    </div>
  );
};

export default withBundle("com.a2z.it.kb.Header")(Header);
