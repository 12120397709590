import "./style.scss";

import { withBundle, WithBundleProps } from "@amzn/react-arb-tools";
import { Header } from "common/components";
import { DEFAULT_LOCALE, LOCALE_KEY } from "config/constants";
import UrlHelper from "helpers/url-helper";
import SearchHelper from "lib/helpers/search-helper";
import { ISearchResult, ISearchResultsOutput, RelevanceType } from "lib/interfaces/search";
import React, { useEffect, useState } from "react";
import { Helmet } from "react-helmet";
import { Link, useLocation } from "react-router-dom";

import { useAppContext } from "../../../AppContext";
import ClickstreamHelper, { FeatureMetric } from "../../../lib/helpers/clickstream-helper";
import LoadingScreen from "../../../modules/components/LoadingScreen";

interface SearchResultsPageProps extends WithBundleProps {}

const SearchResultsPage = (props: SearchResultsPageProps) => {
  const { bundle } = props;
  const [results, setResults] = useState<ISearchResultsOutput>();
  const [loading, setLoading] = useState(false);
  const { clickStreamUrls } = useAppContext();
  const { search } = useLocation();
  const language: string = window.localStorage.getItem(LOCALE_KEY) || DEFAULT_LOCALE;

  useEffect(() => {
    fetchSearchResults();
  }, []);

  useEffect(() => {
    if (!results) {
      return;
    }

    const searchQuery = new URLSearchParams(window.location.search).get("keywords") ?? "";
    const resultsNumber = results.results?.length.toString() ?? "0";
    ClickstreamHelper.createFeatureMetric({
      feature: FeatureMetric.NUMBER_OF_SEARCH_RESULTS,
      language: language,
      searchQuery,
      resultsNumber,
      uri: `${searchQuery}/${resultsNumber}`,
      ...clickStreamUrls,
    });
  }, [results]);

  const fetchSearchResults = async () => {
    setLoading(true);
    const keywords = new URLSearchParams(search).get("keywords") ?? "";

    if (!keywords.trim().length) {
      // If keywords only contain all whitespaces, set results to empty array
      setLoading(false);
      setResults({
        results: [],
        faq: { type: "", path: "", id: 0, content: "", feedbackToken: "" },
        nextToken: "",
        queryId: "",
      });
      return;
    }

    try {
      const results = await SearchHelper.getSearchResults({ keywords: keywords, language: language });
      setLoading(false);
      setResults(results);
    } catch (err) {
      console.error(err);
      setLoading(false);
      setResults(undefined);
    }
  };
  return (
    <>
      <Helmet>
        <link rel="stylesheet" type="text/css" href={UrlHelper.getLegoStylesUrl()} />
      </Helmet>
      <div className="search-result-list">
        <LoadingScreen show={loading} />
        <Header>
          {bundle.formatMessage("header-text", {
            keywords: new URLSearchParams(window.location.search).get("keywords"),
          })}
        </Header>
        <br />
        {results && (
          <SearchResults queryId={results.queryId} results={results?.results} language={language} bundle={bundle} />
        )}
      </div>
    </>
  );
};

interface SearchResultsProps extends WithBundleProps {
  queryId: string;
  language: string;
  results?: ISearchResult[];
}

const SearchResults = ({ bundle, queryId, results, language }: SearchResultsProps) => {
  const { clickStreamUrls } = useAppContext();
  if (!results || results.length == 0) {
    return (
      <h3 style={{ display: "flex", justifyContent: "center", alignItems: "center" }}>
        {bundle.getMessage("no-results-header-text")}
      </h3>
    );
  } else {
    return (
      <>
        {results!.map((result: ISearchResult, index) => {
          const handleResultClick = async () => {
            // Record kendra feedback for CTR (Click through rate) analytics
            SearchHelper.submitSearchFeedback(
              {
                tenant: "kb",
                queryId: queryId,
                resultId: result.id,
                relevanceValue: RelevanceType.RELEVANT,
              },
              language
            );

            // Record clickstream feature metrics
            ClickstreamHelper.createFeatureMetric({
              feature: `${FeatureMetric.SEARCH_RESULT}_${index}`,
              language: language,
              uri: result.articlePath,
              ...clickStreamUrls,
            });
          };
          return (
            <article key={index} title={result.articleTitle} className="search-result-item">
              <header className="search-result-title">
                <Link to={result.articlePath} onClick={handleResultClick}>
                  {result.articleTitle}
                </Link>
              </header>
              <p className="search-result-overview">{result.overview}</p>
            </article>
          );
        })}
      </>
    );
  }
};

export default withBundle("com.a2z.it.kb.SearchResultsPage")(SearchResultsPage);
